import { computed, onMounted, onUnmounted, Ref, ref } from 'vue';
import { ConnectionStatus, Network } from '@capacitor/network';
import { PluginListenerHandle } from '@capacitor/core';

export function useNetworkState () {
    const status: Ref<ConnectionStatus | undefined> = ref(undefined);
    const listener: Ref<PluginListenerHandle | undefined> = ref(undefined);

    onMounted(() => {
        Network.addListener('networkStatusChange', (_status: ConnectionStatus) => {
            status.value = _status;
        }).then(_listener => {
            listener.value = _listener;
        });
    });
    onUnmounted(() => {
        listener.value?.remove();
        listener.value = undefined;
    });
    Network.getStatus().then((_status: ConnectionStatus) => {
        status.value = _status;
    }).catch((error: unknown) => {
        console.error(error);
    });

    return {
        status,
        connected: computed(() => status.value?.connected),
        connectionType: computed(() => status.value?.connectionType)
    };
}

export async function waitTillNetworkAvailable (): Promise<ConnectionStatus['connectionType']> {
    const status = await Network.getStatus();
    if (status.connected) {
        return status.connectionType;
    }

    return new Promise((resolve, reject) => {
        let handle: PluginListenerHandle | undefined;

        Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
            if (status.connected) {
                handle?.remove();
                resolve(status.connectionType);
            }
        }).then(_handle => {
            handle = _handle;
        }).catch(reject);
    });
}
