import {
    onErrorCaptured,
    onMounted,
    onUnmounted,
    Ref,
    watchEffect
} from 'vue';
import { useModal, UseModalPageValue } from '@/helpers/modal';
import { captureException } from '@sentry/capacitor';

type ErrorType = Error | unknown;
type ErrorRef = Ref<ErrorType>;
type ErrorRefList = ErrorRef[] | ErrorRef;

export interface UseErrorModalOptions {
    page?: Ref<UseModalPageValue>;
    error?: ErrorRef;
    errors?: ErrorRefList;
    captureVueErrors?: boolean;
}

export function logError (error: ErrorType): string {
    console.error(error);
    return captureException(error);
}

/**
 * import { useErrorModal } from '@/helpers/errors';
 *
 * const errorModal = useErrorModal();
 * errorModal.add(error);
 */
export function useErrorModal (options: UseErrorModalOptions = {}) {
    const { openCardModal } = useModal({
        page: options.page
    });

    function addError (error: ErrorType) {
        const id = logError(error);
        openCardModal({
            component: () => import('@/components/organisms/Error.vue'),
            componentProps: { id, error }
        });
    }

    if (options?.captureVueErrors !== false) {
        onErrorCaptured(error => {
            addError(error);
            return false;
        });
    }

    function watchError (error: ErrorRef) {
        let registration: () => void | undefined;
        onUnmounted(() => registration?.());
        onMounted(() => {
            registration = watchEffect(() => {
                if (error.value) {
                    addError(error.value);
                }
            });
        });
    }
    if (options?.error) {
        watchError(options.error);
    }

    function watchErrors (errors: ErrorRefList) {
        if (Array.isArray(errors)) {
            errors.forEach(watchError);
        } else {
            watchError(errors);
        }
    }
    if (options?.errors) {
        watchErrors(options.errors);
    }

    return { addError, watchError, watchErrors };
}
