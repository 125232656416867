import {
    AsyncComponentLoader,
    Component,
    defineAsyncComponent,
    inject,
    provide,
    ref,
    Ref
} from 'vue';
import { ComponentRef } from '@ionic/core';
import { modalController, ModalOptions } from '@ionic/vue';
import ModalLoader from '@/components/atoms/ModalLoader.vue';
import { isProduction } from '@/helpers/environment.js';

export interface UseModalPageValue {
    $el: HTMLElement | undefined;
}

export interface UseModalOptions {
    page?: Ref<UseModalPageValue>;
}

type OpenModalOptions<T extends Component> = Omit<ModalOptions<ComponentRef>, 'component'> & {
    component: AsyncComponentLoader<T>
};

export function useModal (options: UseModalOptions) {
    const page = options.page
        ? options.page
        : inject<Ref<UseModalPageValue>>('helpers.modal.page');

    function registerPage (_page: Ref<UseModalPageValue> | UseModalPageValue) {
        provide('helpers.modal.page', ref(_page));
    }

    function openCardModal<T extends Component> (options: OpenModalOptions<T>) {
        openCardModalAsync(options)
            .catch(error => console.error(error));
    }

    async function openCardModalAsync<T extends Component> (options: OpenModalOptions<T>) {
        const component = defineAsyncComponent({
            loader: options.component,
            loadingComponent: ModalLoader,
            delay: 100
        }) as ComponentRef;

        const presentingElement = await modalController.getTop() || page?.value?.$el || undefined;
        const modal = await modalController.create({
            ...options,
            component,
            presentingElement
        });

        return modal.present();
    }

    if (!isProduction) {
        setTimeout(() => {
            if (!page?.value) {
                console.warn('registerPage not called, please call registerPage in setup()!');
            }
        }, 200);
    }

    return { registerPage, openCardModal, openCardModalAsync };
}
